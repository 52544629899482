import { FieldFilter, ODataFilter } from '@zing/grid';

export function isCompositeFilterDescriptor(obj: any): obj is ODataFilter {
  return 'logic' in obj && 'filters' in obj;
}

export function removeFilterAndReturnValue(filters: (ODataFilter | FieldFilter)[], fieldName: string): string | null {
  for (let i = filters.length - 1; i >= 0; i--) {
    const filter = filters[i];
    if (isCompositeFilterDescriptor(filter)) {
      const compositeFilter = filter as ODataFilter;
      const removedValue = removeFilterAndReturnValue(compositeFilter.filters, fieldName);
      if (removedValue) {
        return removedValue;
      }
    } else {
      const singleFilter = filter as FieldFilter;
      if (singleFilter.field === fieldName) {
        filters.splice(i, 1);
        return singleFilter.value;
      }
    }
  }
  return null;
}
