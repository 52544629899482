import { ColumnSettings, MasterTableSettings } from '@zing/grid';

export const columnSettings: ColumnSettings[] = [
  {
    name: 'locationName',
    sort: true,
    order: 0,
    label: 'Name',
    filter: {
      display: 'menu',
      simple: true,
      type: 'text'
    }
  },
  {
    name: 'address',
    sort: true,
    order: 0,
    label: 'Address',
    filter: {
      display: 'menu',
      simple: true,
      type: 'text'
    }
  },
  {
    name: 'phoneNumber',
    sort: true,
    order: 0,
    label: 'Phone Number',
    filter: {
      display: 'menu',
      simple: true,
      type: 'text'
    }
  }
];

export const masterSettings: MasterTableSettings = {
  scrollable: false,
  showCurrentPageReport: false,
  displayPagination: false,
  isLazy: false,
};
