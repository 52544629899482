import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { of, Subject } from 'rxjs';
import { saveAs } from 'file-saver';
import {
  ReportProgress,
  ReportRequest,
  ReportType,
  getReportName,
  reportProgressDisplay
} from '../../../shared/models/report-request.model';
import { ReportingService } from '../../../shared/services/reporting.service';
import { concatMap, filter, finalize, take } from 'rxjs/operators';
import { AuthService } from '../../../shared/auth.service';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { AppConfigurationService } from '../../../shared/services/app-configuration.service';
import { FeatureName } from '../../../shared/enums/feature-name';
import { UserRoleCode } from '../../../persons/enums/user-role-code';
import { LayoutService } from '../../../layouts/layout.service';
import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { ConfirmationWithCommentComponent }
  from '../../../shared/components/dialogs/confirmation-with-dialog/confirmation-with-comment.component';
import {
  cancelReportRequestDialogWithCommentConfig,
  deleteReportDialogWithCommentConfig
} from '../../../incentive-programs/utils/dialog-consts';
import { ColumnSettings, ODataFilter, ODataQuery } from '@zing/grid';
import { columnSettings, masterSettings } from './report-list.config';

@Component({
  selector: 'app-report-list',
  templateUrl: './report-list.component.html',
  styleUrls: ['./report-list.component.scss']
})
export class ReportListComponent implements OnInit, OnDestroy {
  @Input() reportRequested: Subject<boolean>;
  private state: ODataQuery;

  inProgress = false;
  jmccTransactionsReportingEnabled: boolean;
  canSeeReportList = true;
  isAuthorizedToDeleteJmccFinanceReport = false;
  columnSettings: ColumnSettings[];
  masterSettings = masterSettings;
  isLoading = false;
  totalCount = 0;
  data: ReportRequest[] = [];


  defaultFilter: ODataFilter = {
    logic: 'or',
    filters: [
      { field: 'ReportType', operator: 'eq', value: ReportType.Person },
      { field: 'ReportType', operator: 'eq', value: ReportType.Company }
    ]
  };

  readonly jmccReportTypes = [ReportType.JMCCFinance];
  readonly jmccReportRoles = [
    UserRoleCode.JMJewelerProgramsFinance,
    UserRoleCode.PlatformAdministrator,
    UserRoleCode.JMPointOfSaleAdministrator
  ];
  readonly jmccReportRolesAuthorizedToDelete = [UserRoleCode.PlatformAdministrator];

  reportTypes = [
    { 'key': ReportType[ReportType.Company], 'value': ReportType.Company },
    { 'key': ReportType[ReportType.Person], 'value': ReportType.Person }
  ];

  currentDate = new Date().toISOString();
  getReportType = type => getReportName(type);

  get reportProgress() {
    return ReportProgress;
  }

  constructor(
    private reportService: ReportingService,
    private authService: AuthService,
    private appConfigurationService: AppConfigurationService,
    private dialog: MatDialog,
    private layoutService: LayoutService
  ) { }

  ngOnInit() {
    this.getFeatureFlags(); // when featureFlag is removed call loadData() instead
    this.reportRequested.subscribe(() => this.getData());
    this.isAuthorizedToDeleteJmccFinanceReport = this.authService.hasAnyRole(this.jmccReportRolesAuthorizedToDelete);
    this.createReportStatusesFilter();
  }

  createReportStatusesFilter(){
    const reportStatusesFilter = columnSettings.find(x => x.name === "reportProgress");
    reportProgressDisplay.forEach(element => {
      reportStatusesFilter.filter.options.push({label: element.key, value: element.value});
    });
  }

  createReportTypesFilter(){
    const reportTypesFilter = columnSettings.find(x => x.name === "reportType");
    this.reportTypes.forEach(element => {
      reportTypesFilter.filter.options.push({label: element.key, value: element.value});
    });
  }

  loadData(state: ODataQuery){
    this.state = state;
    this.getData();
  }

  getData() {
    this.isLoading = true;
    this.reportService
      .query(this.state)
      .pipe(take(1),
        finalize(() => this.isLoading = false))
      .subscribe(
        data => {
          if (data) {
            this.data = data.items;
            this.totalCount = data.totalRecordCount;
          }
        });
  }

  ngOnDestroy(): void {
    this.reportRequested.unsubscribe();
  }

  onDelete(id: string, reportType: ReportType) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = deleteReportDialogWithCommentConfig;
    this.dialog
      .open(ConfirmationWithCommentComponent, dialogConfig)
      .afterClosed()
      .pipe(take(1))
      .subscribe(
        comment => {
          if (comment) {
            this.onDeleteYes(id, reportType, comment);
          }
        }
      );
  }

  onDeleteYes(id: string, reportType: ReportType, comment: string) {
    this.inProgress = true;
    this.reportService.delete(id, comment)
      .pipe(
        take(1),
        finalize(() => this.inProgress = false))
      .subscribe(() => {
        this.layoutService.showUIMessage('Report deleted successfully.');
        this.getData();
      },
      err => {
        if (err.status === HttpStatusCode.Forbidden) {
          this.layoutService.showUIMessage('You are not allowed to delete this report.');
        } else {
          this.layoutService.showUIMessage('Report deletion failed.');
        }
      });
  }

  onRefresh(report: ReportRequest) {
    this.isLoading = true;
    const editedReport: ReportRequest = this.data.find(x => x.id === report.id);
    this.reportService
      .getById(report.id)
      .pipe(take(1),
        filter(data => !!data),
        finalize(() => this.isLoading = false))
      .subscribe(
        data => {
          editedReport.reportBlobId = data.reportBlobId;
          editedReport.failed = data.failed;
          editedReport.reportName = data.reportName;
          editedReport.reportProgress = data.reportProgress;
        });
  }

  onDownload(id: string, reportName: string) {
    this.isLoading = true;
    this.reportService
      .receiveReport(`/api/Reports/${id}/download`)
      .pipe(take(1),
        finalize(() => this.isLoading = false))
      .subscribe(
        data => {
          saveAs(data, this.getReportName(reportName));
        },
        () => {
          this.layoutService.showUIMessage('Report download failed.');
        }
      );
  }

  onCancel(report: ReportRequest) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = cancelReportRequestDialogWithCommentConfig;
    this.dialog
      .open(ConfirmationWithCommentComponent, dialogConfig)
      .afterClosed()
      .pipe(take(1))
      .subscribe(
        comment => {
          if (comment) {
            this.cancelReport(report, comment);
          }
        }
      );
  }

  cancelReport(report: ReportRequest, comment: string) {
    this.isLoading = true;
    this.reportService
      .cancelScheduledReport(report.id, comment)
      .pipe(take(1),
        finalize(() => this.isLoading = false))
      .subscribe(
        () => {
          this.onRefresh(report);
        },
        (error: HttpErrorResponse) => {
          this.layoutService.showUIMessage(error.error.message);
        }
      );
  }

  getFeatureFlags() {
    this.isLoading = true;

    this.appConfigurationService.getFeatureFlag(FeatureName.JmccTransactionsReporting)
      .pipe(
        take(1),
        finalize(() => this.isLoading = false)
      )
      .subscribe(flagEnabled => {
        this.jmccTransactionsReportingEnabled = flagEnabled;
        if (!flagEnabled && this.authService.isJewelerProgramsFinance) {
          this.canSeeReportList = false;
        } else {
          this.defaultFilter = { logic: 'or', filters: this.getReportFilters() };
          this.createReportTypesFilter();
        }
        this.columnSettings = columnSettings;
      });
  }

  getReportFilters() {
    let list = [];

    if (this.jmccTransactionsReportingEnabled && this.authService.hasAnyRole(this.jmccReportRoles)) {
      list = this.jmccReportTypes.map(element => ({ field: 'ReportType', operator: 'eq', value: element }));
      this.reportTypes.push({ 'key': 'JMCC Finance', 'value': ReportType.JMCCFinance });
    }

    if (!this.authService.isJewelerProgramsFinance) {
      list.push({ field: 'ReportType', operator: 'eq', value: ReportType.Person });
      list.push({ field: 'ReportType', operator: 'eq', value: ReportType.Company });
    }

    return list;
  }

  getReportName(reportName: string) {
    return reportName ? reportName : `Report_for_${new Date().toTimeString()}.xlsx`;
  }

  showDeleteIcon(report: ReportRequest) {
    const isJmccFinanceReport = this.jmccReportTypes.includes(report.reportType);
    const canDeleteReport = report.reportBlobId || report.failed;

    if (report.failed && isJmccFinanceReport) {
      return false;
    }

    if (this.authService.isViewOnly) {
      return false;
    }

    if (!canDeleteReport) {
      return false;
    }

    if (!isJmccFinanceReport) {
      return true;
    }

    if (isJmccFinanceReport && this.isAuthorizedToDeleteJmccFinanceReport) {
      return true;
    }

    return false;
  }
}
