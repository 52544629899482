<div class="portal-example-page">
  <div class="portal-example-pager__content">
    <div class="example-container list-container">
      <zing-grid 
        [data]="data"
        [tableSettings]="columnSettings"
        [masterSettings]="masterSettings"
        [totalCount]="totalCount"
        [dataKey]="'id'"
        (oDataQueryChanged)="loadData($event)">

        <ng-template zingCustomColumn columnName="type" let-screeningResult>
          {{ screeningResult.formattedType }}
        </ng-template>

        <ng-template #action let-screeningResult>
          <div *ngIf="screeningResult.status === 1" class="text-center">
            <button type="button"
              class="btn btn-link mr-3 px-0 actions"
              (click)="onViewReport(screeningResult)">
              <u style="text-wrap: wrap;">View Report</u>
            </button>
          </div>
        </ng-template>

      </zing-grid>

      <button [hidden]="hideInitiateButton" class="initiate-screening float-right" [disabled]="dataLoading" mat-raised-button color="primary" (click)="onInitiateScreening()">
        Initiate Screening
      </button>
      <app-in-progress [loading]="dataLoading"></app-in-progress>
    </div>
    <p *ngIf="isBlockedByCompany">
      Person blocked due to associated Company being OFAC blocked. See 
      <a [routerLink]="['/companies/detail', person.companyId]">Company Details</a> for {{ companyName }}.
    </p>
  </div>
</div>
