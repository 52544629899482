<div class="row flex-layout title-left-side" fxLayout="row" fxLayoutAlign="left">
  <div class="col-sm-12 col-xl-2 col-lg-4">
    <p class="portal-top-text pr-2">View all persons</p>
  </div>
  <div class="col-sm-12 col-xl-2 col-lg-4">
    <button *ngIf="showReinviteButton && isPartnerGatewayAdmin" class="mt-4 fit-content cancel-button" mat-raised-button color="primary" 
    [disabled]="isResendInvitationsInProgress" (click)="onClickResendInvitations()">
      Resend invitations
    </button>
  </div>
  <div class="col-sm-12 col-xl-4 col-lg-4">
    <p *ngIf="totalInvites > 0" class="portal-top-text pr-2">Sending {{ sentInvites }} of {{ totalInvites }}...</p>
  </div>
</div>

<div class="table-and-butons">
  <div
    *ngIf="companyId && !companyHasRestrictedAccess && !isViewOnly"
    class="title-right-side mt-3 mr-2">
      <a
        [routerLink]="['invite']"
        class="btn-portal-add mr-4"
        matTooltip="Invite person"
        matTooltipPosition="before"
        matTooltipClass="quartz-tooltip">
        <img class="mb-2" src="assets/images/JMicons/JM-icon-15.png" alt="add">
      </a>
      <a
        (click)="openCompanyLinkingDialog()"
        class="btn-portal-add mr-4"
        matTooltip="Link person to company"
        matTooltipPosition="before"
        matTooltipClass="quartz-tooltip">
        <i class="fas fa-link"></i>
      </a>
  </div>
  <div class="portal-example-pager__content">
    <div class="example-container list-container">
      <zing-grid 
        [data]="data" 
        [tableSettings]="columnSettings" 
        [masterSettings]="masterSettings"
        [totalCount]="totalCount" 
        [dataKey]="'id'"
        [isLoading]="isLoading"
        [additionalFilter]="defaultFilter"
        (oDataQueryChanged)="loadData($event)">

        <ng-template zingCustomColumn columnName="approvalStatus" let-person>
          {{ approvalStatus[person.approvalStatus] | approvalstatus }}
        </ng-template>

        <ng-template #action let-person>
          <div class="text-right">
            <mat-icon
              *ngIf="person? isPersonReinvitable(person) : false"
              (click)="resendInvite(person.email)"
              class="cursorButtons" 
              matTooltip="Resend Invite"
              matTooltipPosition="before"
              matTooltipClass="quartz-tooltip">send</mat-icon>
            <mat-icon
              [routerLink]="['/persons/details', person.id]" 
              class="cursorButtons" 
              matTooltip="Edit person details"
              matTooltipPosition="before"
              matTooltipClass="quartz-tooltip">edit</mat-icon>
            <mat-icon
              *ngIf="(isSuperOrPlatformAdmin || isMembershipAdmin) && !isGuestPerson(person)"
              (click)="openDeleteDialog(person.id)" 
              class="cursorButtons" 
              matTooltip="Delete person"
              matTooltipPosition="before"
              matTooltipClass="quartz-tooltip">delete</mat-icon>
            <mat-icon
              *ngIf="person.companyId"
              [routerLink]="['/companies/detail', person.companyId]"
              class="cursorButtons" 
              matTooltip="View Person company"
              matTooltipPosition="before"
              matTooltipClass="quartz-tooltip">work</mat-icon>
            <mat-icon
              *ngIf="!person.companyId"
              class="cursorButtons" 
              matTooltip="No registered company"
              matTooltipPosition="before"
              matTooltipClass="quartz-tooltip">work_off</mat-icon>            
          </div>
        </ng-template>
      </zing-grid>
      <app-simple-pagination [hasMore]="hasMore" (pageChangedEvent)="onPageChange($event)"></app-simple-pagination> 
    </div>
  </div>
</div>
