import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable ,  Subject } from 'rxjs';
import { environment } from '../../../environments/environment';
import { BaseHttpService } from '../../shared/services/base-http.service';
import { Person } from '../models/person.model';
import { PaginatedResponse } from '../../shared/models/paginated.response';
import { PersonRole } from '../models/person-role.model';
import { FilterDefinition } from '../../shared/models/filter-definition';
import { ContinuousList } from '../../shared/models/continuous-list';
import { createDataQueryModel, ODataQuery } from '@zing/grid';

@Injectable({
  providedIn: 'root'
})
export class PersonService extends BaseHttpService<Person> {
  public person: Person;

  // Observable sources
  private companyRoleUpdate = new Subject<PersonRole>();
  private locationRoleUpdate = new Subject<PersonRole>();

  // Observable streams
  companyRoleUpdateAnnounced = this.companyRoleUpdate.asObservable();
  locationRoleUpdateAnnounced = this.locationRoleUpdate.asObservable();

  constructor(
    private http: HttpClient
  ) {
    super(http);
    this.apiDomain = environment.membershipApiUrl;
    this.endpoint = 'Persons';
  }

  getProfileInfo(): Observable<Person> {
    return this.http
      .get<Person>(this.getFullEndpointUrl() + '/profile');
  }

  /**
   * Return all persons associated with specific company.
   *
   * @param companyId Company unique identifier.
   */
  getPersonsByCompanyId(filter: ODataQuery = null, companyId: string): Observable<PaginatedResponse<Person>> {
    const oDataModel1 = createDataQueryModel(filter);
    if (!oDataModel1.filter) {
      oDataModel1.filter = `companyId eq guid'${companyId}'`;
    } else {
      oDataModel1.filter = `${oDataModel1.filter} and companyId eq guid'${companyId}'`;
    }
    return this.http
      .post<PaginatedResponse<Person>>(`${this.apiDomain}/api/${this.endpoint}/search`, oDataModel1);
  }

  AnnounceCompanyRoleUpdate(personRole: PersonRole) {
    if (personRole != null) {
      this.companyRoleUpdate.next(personRole);
    }
  }

  AnnounceLocationRoleUpdate(personRole: PersonRole) {
    if (personRole != null) {
      this.locationRoleUpdate.next(personRole);
    }
  }

  /**
   * Get Account Lockout Details.
   *
   * @param userId ReferenceId of the Person.
   */
  getAccountLockoutDetails(userId: string): any {
    return this.http
      .get(`${environment.identityApiUrl}/Users/${userId}/Lockout`);
  }

  /**
   * Set Account Lockout Details.
   *
   * @param userId ReferenceId of the Person.
   */
  setAccountLockoutDetails(userId: string) {
    return this.http
      .put(`${environment.identityApiUrl}/Users/${userId}/Lockout`, {});
  }

  getPersonsContinues(filter: ODataQuery = null): Observable<ContinuousList<Person>>{
    const oDataModel = createDataQueryModel(filter);
    return this.http
      .post<ContinuousList<Person>>(`${this.apiDomain}/api/${this.endpoint}/search/continuous`, oDataModel);
  }

  getPersonsContinuesByCompanyId(filter: ODataQuery = null, companyId: string): Observable<ContinuousList<Person>>{
    const oDataModel1 = createDataQueryModel(filter);
    if (!oDataModel1.filter) {
      oDataModel1.filter = `companyId eq guid'${companyId}'`;
    } else {
      oDataModel1.filter = `${oDataModel1.filter} and companyId eq guid'${companyId}'`;
    }
    return this.http
      .post<ContinuousList<Person>>(`${this.apiDomain}/api/${this.endpoint}/search/continuous`, oDataModel1);
  }
}
