import { ColumnSettings, MasterTableSettings } from '@zing/grid';

export const columnSettings: ColumnSettings[] = [
  {
    name: 'firstName',
    sort: true,
    order: 0,
    label: 'First Name',
    width: 80,
    filter: {
      display: 'menu',
      simple: true,
      type: 'text'
    }
  },
  {
    name: 'lastName',
    sort: true,
    order: 1,
    label: 'Last Name',
    width: 80,
    filter: {
      display: 'menu',
      simple: true,
      type: 'text'
    }
  },
  {
    name: 'email',
    sort: true,
    order: 2,
    label: 'E-mail',
    width: 100,
    filter: {
      display: 'menu',
      simple: true,
      type: 'text'
    }
  },
  {
    name: 'isActive',
    sort: true,
    order: 3,
    label: 'Is Active',
    width: 50,
    filter: {
      display: 'menu',
      dropdown: true,
      matchMode: 'in',
      options: [
        { label: 'Is True', value: true },
        { label: 'Is False', value: false }
      ],
      multiSelect: false
    },
  },
  {
    name: 'approvalStatus',
    sort: true,
    order: 4,
    label: 'Status',
    width: 50,
    filter: {
      display: 'menu',
      simple: true,
      type: 'text'
    }
  },
  {
    name: 'registrationId',
    sort: true,
    order: 4,
    label: 'Registration Id',
    width: 50,
    filter: {
      display: 'menu',
      simple: true,
      type: 'text'
    }
  },
  {
    name: 'action',
    sort: false,
    order: 5,
    label: 'Action',
    width: 80
  }
];

export const masterSettings: MasterTableSettings = {
  scrollable: false,
  showCurrentPageReport: false,
  displayPagination: false
};
