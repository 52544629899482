import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable} from '@angular/core';
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import { MessagePackHubProtocol } from '@microsoft/signalr-protocol-msgpack';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { AuthService } from '../../shared/auth.service';
import { ZingHubHttpClient } from '../../shared/components/signalr-clients/zing-hub-http-client';
import { PaginatedResponse } from '../../shared/models/paginated.response';
import { BaseHttpService } from '../../shared/services/base-http.service';
import { UpdateScreeningDecisionRequest } from '../models/review-report-request';
import { ScreeningLog, ScreeningProcess, ScreeningProcessRequest, ScreeningResult } from '../models/screening-process';
import { MatchingStatus } from '../enums/matching-status';
import { ReviewStatus } from '../enums/review-status';
import { DecisionType } from '../enums/decision-type';
import { removeFilterAndReturnValue } from '../../utils/filter-help-functions';
import { FieldFilter, ODataFilter, ODataQuery } from '@zing/grid';

@Injectable({providedIn: 'root'})
export class ScreeningService extends BaseHttpService<ScreeningProcess> {

  dataReceived = new EventEmitter<object>();

  private connectionEstablished$ = new BehaviorSubject(false);
  private _hubConnection: HubConnection;
  private groupId = null;
  hubConnectionRetryCount = 0;

  constructor(
    private http: HttpClient,
    private authService: AuthService) {
    super(http);
    this.apiDomain = environment.reportingApiUrl;
    this.endpoint = 'processes';
    this.createConnection();
    this.registerOnServerEvents();
  }

  private createConnection(): void {
    this._hubConnection = new HubConnectionBuilder()
      .withUrl(`${this.apiDomain}/reporthub`, {
        httpClient: new ZingHubHttpClient(),
        accessTokenFactory: () => this.authService.accessToken
      })
      .withAutomaticReconnect()
      .withHubProtocol(new MessagePackHubProtocol())
      .build();
  }

  /**
   * Starts a connection with the Hub
   */
  startConnection(): void {
    if (this.connectionEstablished$.value) {
      throw new Error('Connection already established or groupId is null.');
    }

    this._hubConnection
      .start()
      .then(() => this.connectionEstablished$.next(true))
      .catch(() => {
        // retry 3 times if error
        if (this.hubConnectionRetryCount < 3) {
          this.hubConnectionRetryCount++;
          this.startConnection();
        } else {
          this.endConnection();
        }
      });
  }

  /**
   * Registers the possible events that may be generated by the hub
   */
  private registerOnServerEvents(): void {
    this._hubConnection.on('screeningState', (id: any, state: any) => {
      this.dataReceived.emit({ id, state });
    });
  }

  /**
   * Ends the connection with the Hub
   */
  public endConnection(): void {
    if (this.connectionEstablished$.value) {
      this._hubConnection.stop();
    };

    this.reset();
  }

  /**
   * Resets the field values
   */
  private reset(): void {
    this.connectionEstablished$.next(false);
    //this.requestInProgress$.next(false);
    this.groupId = null;
  }

  initiate(screeningProcessRequest: ScreeningProcessRequest) {
    const apiUrl = `${this.apiDomain}/api/${this.endpoint}/initiate`;
    return this.http.post<ScreeningProcess>(apiUrl, screeningProcessRequest);
  }

  /**
   * Make http request to get the items
   *
   * @param queryFilter Object containing filter information
   */
  queryScreeningProcesses(
    isScheduled: boolean,
    filterDefinition: ODataQuery = null
  ): Observable<PaginatedResponse<ScreeningProcess>> {
    filterDefinition = this.setScheduledFilter(filterDefinition, isScheduled);
    const oDataModel = this.createDataQueryModel(filterDefinition);

    return this.http
      .post<PaginatedResponse<ScreeningProcess>>(`${this.apiDomain}/api/processes/search`, oDataModel);
  }

  /**
   * Make http request to get the items
   *
   * @param queryFilter Object containing filter information
   */
  queryScreeningResults(filter: string, filterDefinition: ODataQuery = null): Observable<PaginatedResponse<ScreeningResult>> {
    const queryFilterDeepCopy = JSON.parse(JSON.stringify(filterDefinition));
    this.updateMatchingStatusFilter(queryFilterDeepCopy.filter?.filters|| []);
    const oDataModel = this.createDataQueryModel(queryFilterDeepCopy);

    oDataModel.filter = !oDataModel.filter ? filter : `${oDataModel.filter} and ${filter}`;

    return this.http
      .post<PaginatedResponse<ScreeningResult>>(`${this.apiDomain}/api/screeningentities/search`, oDataModel);
  }

  getScreeningLogs(processId: string): Observable<ScreeningLog[]> {
    const apiUrl = `${this.apiDomain}/api/${this.endpoint}/${processId}/logs`;
    return this.http.get<ScreeningLog[]>(apiUrl);
  }

  updateScreeningDecision(updateRequest: UpdateScreeningDecisionRequest) {
    return this.http.put(`${this.apiDomain}/api/screeningentities/decision`, updateRequest);
  }

  updateMatchingStatusFilter(filters: (ODataFilter | FieldFilter)[]) {
    filters.forEach(filter => {
      if ('filters' in filter) {
        const removedFilterValue = removeFilterAndReturnValue(filter.filters, 'matchingStatus');
        if (removedFilterValue) {
          const result = this.getReviewStatusAndDecisionTypeByMatchingStatus(removedFilterValue);
          filter.filters.push({ field: 'reviewStatus', operator: 'eq', value: result.reviewStatus });
          filter.filters.push({ field: 'decisionType', operator: 'eq', value: result.decisionType });
        }
      }
    });
  }

  getReviewStatusAndDecisionTypeByMatchingStatus(matchingStatus: string) {
    switch(matchingStatus) {
      case MatchingStatus.PossibleMatch:
        return { reviewStatus: ReviewStatus.Pending, decisionType: DecisionType.None };
      case MatchingStatus.ReviewedBlocked:
        return { reviewStatus: ReviewStatus.Reviewed, decisionType: DecisionType.Approve };
      case MatchingStatus.ReviewedNoActionNeeded:
        return { reviewStatus: ReviewStatus.Reviewed, decisionType: DecisionType.Decline };
    }
  }

  private setScheduledFilter(filterDefinition: ODataQuery, isScheduled: boolean) {
    const queryFilterDeepCopy = JSON.parse(JSON.stringify(filterDefinition));
    if (!queryFilterDeepCopy.filter?.filters) {
      queryFilterDeepCopy.filter = {
        logic: 'and',
        filters: []
      };
    }
    queryFilterDeepCopy.filter.filters.push({
      field: 'data.initiatedBy',
      operator: isScheduled ? 'eq' : 'neq',
      value: 'Scheduler'
    });

    return queryFilterDeepCopy;
  }
}
