import { Component, Inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { DefaultConfirmationWithCommentData } from '../../../models/default-confirmation-with-comment-data';

@Component({
  selector: 'app-base-confirmation-with-comment',
  templateUrl: './base-confirmation-with-comment.component.html',
  styleUrls: ['./base-confirmation-with-comment.component.scss']
})
export class BaseConfirmationWithCommentComponent {
  public title : string;
  public displayText : string;
  public inputPlaceholderMessage : string;
  public confirmActionButtonText: string;

  public comment: string;
  public isConfirmed = false;
  public confirmationForm: UntypedFormGroup;
  
  get commentCtrl() { return this.confirmationForm.controls['comment']; }

  constructor(
    public fb: UntypedFormBuilder,
    public dialogRef: MatDialogRef<BaseConfirmationWithCommentComponent>,
    @Inject(MAT_DIALOG_DATA) data: DefaultConfirmationWithCommentData
  ) {
    if (data?.title) {
      this.title = data.title;
    }

    if (data?.displayText) {
      this.displayText = data.displayText;
    }

    if (data?.isConfirmed) {
      this.isConfirmed = data.isConfirmed;
    }
  }

  ngOnInit() {
    this.createForm();
  }

  /**
   * Initialize FormGroup instance.
   */
  public createForm() {
    this.confirmationForm = this.fb.group({
      comment: new UntypedFormControl('', [
        Validators.required,
        Validators.maxLength(1000),
        Validators.minLength(25)
      ])
    });
  }

  /**
   * Return true if control is touched or dirty and has invalid state.
   *
   * @param controlName Name of the FormControl.
   * @param errorType Type of error.
   */
  public hasErrorOfType(controlName: string, errorType: string): boolean {
    const ctrl = this.confirmationForm.controls[controlName];
    return ctrl && (ctrl.touched || ctrl.dirty) && ctrl.hasError(errorType);
  }

  closeDialog() {
    this.dialogRef.close();
  }

  onConfirm() {
    this.isConfirmed = true;
  }
}
