<div *ngIf="!isViewOnly" class="float-right mt-3 mr-2">
    <a [routerLink]="['/locations/company',companyId,'location']"
      class="btn-portal-add mr-4"
      matTooltip="Add new location"
      matTooltipPosition="before"
      matTooltipClass="quartz-tooltip">
      <img src="assets/images/JMicons/JM-icon-15.png" alt="add">
    </a>
  </div>
  <p class="portal-top-text">View all locations.</p>
  <div class="portal-example-page">
    <div class="portal-example-pager__content">
      <div class="example-container list-container">
        <zing-grid 
          [data]="data"
          [tableSettings]="columnSettings"
          [masterSettings]="masterSettings"
          [dataKey]="'id'">

          <ng-template zingCustomColumn columnName="address" let-location>
            {{ location.address | address }}
          </ng-template>

          <ng-template #action let-location>
            <div class="text-center">
              <mat-icon *ngIf="!companyHasRestrictedAccess" class="cursorButtons" [routerLink]="['/locations/company',companyId,'location', location.locationID]"
                matTooltip="Edit location details"
                matTooltipPosition="before"
                matTooltipClass="quartz-tooltip">edit</mat-icon>
              <mat-icon class="cursorButtons" (click)="onDelete(location.locationID)"
                *ngIf="isSuperOrPlatformAdmin || isMembershipAdmin"
                matTooltip="Delete location"
                matTooltipPosition="before"
                matTooltipClass="quartz-tooltip">delete</mat-icon>
            </div>
          </ng-template>

        </zing-grid>
        <app-in-progress [loading]="inProgress"></app-in-progress>
      </div>
    </div>
  </div>
  