import { Component, Inject} from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { BaseConfirmationWithCommentComponent } from '../base-confirmation-with-comment/base-confirmation-with-comment.component';
import { DefaultConfirmationWithCommentData } from '../../../models/default-confirmation-with-comment-data';

@Component({
  selector: 'app-delete-confirmation-with-comment',
  templateUrl: '../base-confirmation-with-comment/base-confirmation-with-comment.component.html',
  styleUrls: ['../base-confirmation-with-comment/base-confirmation-with-comment.component.scss']
})
export class DeleteConfirmationWithCommentComponent extends BaseConfirmationWithCommentComponent {
    public title = 'Delete';
    public displayText = 'Are you sure you want to delete this item?';
    public override inputPlaceholderMessage = 'Reason to delete';
    public override confirmActionButtonText = 'Delete';
  
    constructor(
      public fb: UntypedFormBuilder,
      public dialogRef: MatDialogRef<DeleteConfirmationWithCommentComponent>,
      @Inject(MAT_DIALOG_DATA) data: DefaultConfirmationWithCommentData
    ) {
      super(fb, dialogRef, data);
    }
}
