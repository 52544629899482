import { Component, OnInit, OnDestroy } from '@angular/core';
import { LayoutService } from '../../../layouts/layout.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { DeleteConfirmationWithCommentComponent } from '../../../shared/components/dialogs/delete-confirmation-with-comment/delete-confirmation-with-comment.component';
import { LocationService } from '../../../shared/services/location.service';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../../../shared/auth.service';
import { Company } from '../../../companies/models/company';
import { EntityResolved } from '../../../shared/models/entity-resolved';
import { environment } from '../../../../environments/environment';
import { columnSettings, masterSettings } from './location-list.config';

@Component({
  selector: 'app-location-list',
  templateUrl: './location-list.component.html',
  styleUrls: ['./location-list.component.scss']
})
export class LocationListComponent implements OnInit, OnDestroy {

  inProgress = false;
  subscriptions = new Array<Subscription>();
  public loadingData = false;
  public data: any;
  public companyId: string;
  public companyHasRestrictedAccess = false;

  columnSettings = columnSettings;
  masterSettings = masterSettings;

  guestExperianceEnabled = environment.guestExperienceEnabled;

  get isSuperOrPlatformAdmin() {
    return this.authService.isSuperOrPlatformAdministrator;
  }

  get isViewOnly() {
    return this.authService.isViewOnly;
  }

  get isMembershipAdmin() {
    return this.authService.isMembershipAdmin;
  }

  constructor(
    private layoutService: LayoutService,
    private locationService: LocationService,
    private authService: AuthService,
    private activatedRoute: ActivatedRoute,
    public matAlert: MatDialog,
  ) { }

  ngOnInit() {
    this.companyId = this.activatedRoute.snapshot.params['id'];
    this.getCompanyHasRestrictedAccess();
    this.getLocations();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  getCompanyHasRestrictedAccess() {
    if (this.companyId) {
      const resolved = this.activatedRoute.snapshot.data['company'] as EntityResolved<Company>;
      if (resolved.entity) {
        this.companyHasRestrictedAccess = resolved.entity.accessMode === 1;
      } else {
        this.companyHasRestrictedAccess = true;
        this.layoutService.showUIMessage('Unable to get company');
      }
    }
  }

  /**
   * Get locations using service
   */
  getLocations() {
    if(this.companyId){
      this.loadingData = true;
      const sub = this.locationService
        .getLocationsForCompanyId(this.companyId)
        .subscribe(
          data => {
            this.loadingData = false;
            this.data = data;
          },
          () => {
            this.loadingData = false;
            this.layoutService.showUIMessage('Unable to get locations');
          }
        );

      this.subscriptions.push(sub);
    }
  }

  /**
   * Method will display confirmation dialog and if user confirm delete action
   * it will call method to delete location
   *
   * @param id Unique identifier for location which should be deleted
   */
  onDelete(id: string) {
    const sub = this.matAlert
      .open(DeleteConfirmationWithCommentComponent)
      .afterClosed()
      .subscribe(
        comment => {
          if (comment) {
            this.deleteLocation(this.companyId, id, comment);
          }
        }
      );

    this.subscriptions.push(sub);
  }

  /**
   * Use service to delete company
   *
   * @param id Unique identifier
   */
  deleteLocation(companyId, id: string, comment?: string): void {
    this.inProgress = true;
    const sub = this.locationService.deleteLocation(companyId, id, comment)
      .subscribe(
        () => {
          this.inProgress = false;
          this.layoutService.showUIMessage('Location successfully deleted');
          this.getLocations();
        },
        () => {
          this.inProgress = false;
          this.layoutService.showUIMessage('Location failed to be deleted');
        }
      );

    this.subscriptions.push(sub);
  }
}
