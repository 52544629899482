import { UserRoleCode } from './persons/enums/user-role-code';
import { ModuleName } from './shared/enums/module-name';

const defaultRoles = [
  UserRoleCode.SuperAdministrator,
  UserRoleCode.PlatformAdministrator,
  UserRoleCode.ViewOnly
];

type moduleRoleAccessType = Record<ModuleName, UserRoleCode[]>;

const moduleRoleAccessMap:  moduleRoleAccessType= {
  [ModuleName.Communication]: [
    ...defaultRoles,
    UserRoleCode.RegulatoryComplianceAdministrator,
    UserRoleCode.JMAuctionAdministrator,
    UserRoleCode.JewelerPagesAdministrator,
    UserRoleCode.JMStudioAdministrator,
    UserRoleCode.JMPointOfSaleAdministrator,
    UserRoleCode.JMMarketplaceAdministrator,
    UserRoleCode.JMPartnerGatewayAdministrator,
    UserRoleCode.JMAppraisalAdministrator,
    UserRoleCode.JMShippingRegistrationAdministrator,
    UserRoleCode.JMPersonalLinesClaimsAdministrator,
    UserRoleCode.JMCommercialLinesAdministrator,
    UserRoleCode.JMShippingAdministrator,
    UserRoleCode.JMCarePlanAdministrator
  ],
  [ModuleName.Risk]: [
    ...defaultRoles,
    UserRoleCode.RegulatoryComplianceAdministrator,
  ],
  [ModuleName.Identity]: [
    ...defaultRoles,
    UserRoleCode.JMIdentitySupportLevel1
  ],
  [ModuleName.Membership]: [
    ...defaultRoles,
    UserRoleCode.JMCarePlanAdministrator,
    UserRoleCode.JMCarePlanSales,
    UserRoleCode.JMCarePlanFinance,
    UserRoleCode.JMCarePlanCallCenter,
    UserRoleCode.JMCarePlanClaimProcessor,
    UserRoleCode.JMCarePlanClaimProcessingManager,
    UserRoleCode.JMShippingAdministrator,
    UserRoleCode.JMCommercialLinesAdministrator,
    UserRoleCode.JMPersonalLinesClaimsAdministrator,
    UserRoleCode.JMPersonalLinesClaimsExaminer,
    UserRoleCode.JMPersonalLinesClaimsCallCenter,
    UserRoleCode.JMAppraisalAdministrator,
    UserRoleCode.JMPartnerGatewayAdministrator,
    UserRoleCode.PlatformSupportL1,
    UserRoleCode.JMMarketplaceAdministrator,
    UserRoleCode.JMPointOfSaleAdministrator,
    UserRoleCode.JMStudioAdministrator,
    UserRoleCode.JMMembershipReportViewer,
    UserRoleCode.JMJewelerProgramsFinance,
    UserRoleCode.JewelerPagesAdministrator,
    UserRoleCode.JMAuctionAdministrator,
    UserRoleCode.RegulatoryComplianceAdministrator,
    UserRoleCode.MembershipAdministrator
  ],
  [ModuleName.Content]: [
    ...defaultRoles,
    UserRoleCode.RegulatoryComplianceAdministrator,
    UserRoleCode.JMAuctionAdministrator,
    UserRoleCode.JewelerPagesAdministrator,
    UserRoleCode.JMStudioAdministrator,
    UserRoleCode.JMPointOfSaleAdministrator,
    UserRoleCode.JMMarketplaceAdministrator,
    UserRoleCode.JMPartnerGatewayAdministrator,
    UserRoleCode.JMAppraisalAdministrator,
    UserRoleCode.JMShippingRegistrationAdministrator,
    UserRoleCode.JMPersonalLinesClaimsAdministrator,
    UserRoleCode.JMCommercialLinesAdministrator,
    UserRoleCode.JMShippingAdministrator,
    UserRoleCode.JMCarePlanAdministrator
  ],
  [ModuleName.Payment]: [
    ...defaultRoles,
  ],
  [ModuleName.Shipping]: [
    ...defaultRoles
  ],
  [ModuleName.Billing]: [
    ...defaultRoles,
  ],
  [ModuleName.Appraisals]: [
    ...defaultRoles,
    UserRoleCode.JMAppraisalAdministrator,
    UserRoleCode.JMAppraisalSales,
    UserRoleCode.JMCustomerService
  ],
  [ModuleName.PointOfSaleIntegration]: [
    ...defaultRoles,
    UserRoleCode.JMPointOfSaleAdministrator,
    UserRoleCode.JMCustomerService
  ],
  [ModuleName.DiamondMarketplace]: [
    ...defaultRoles,
    UserRoleCode.JMMarketplaceAdministrator
  ],
  [ModuleName.Memo]: [
    ...defaultRoles,
  ],
  [ModuleName.Studio]: [
    ...defaultRoles,
    UserRoleCode.JMStudioAdministrator
  ],
  [ModuleName.JewelerPages]: [
    ...defaultRoles,
    UserRoleCode.JewelerPagesAdministrator
  ],
  [ModuleName.Auction]: [
    ...defaultRoles,
    UserRoleCode.JMAuctionAdministrator
  ],
  [ModuleName.PromoCode]: [
    ...defaultRoles,
    UserRoleCode.RegulatoryComplianceAdministrator,
    UserRoleCode.JMAuctionAdministrator,
    UserRoleCode.JewelerPagesAdministrator,
    UserRoleCode.JMStudioAdministrator,
    UserRoleCode.JMPointOfSaleAdministrator,
    UserRoleCode.JMMarketplaceAdministrator,
    UserRoleCode.JMPartnerGatewayAdministrator,
    UserRoleCode.JMAppraisalAdministrator,
    UserRoleCode.JMShippingRegistrationAdministrator,
    UserRoleCode.JMPersonalLinesClaimsAdministrator,
    UserRoleCode.JMCommercialLinesAdministrator,
    UserRoleCode.JMShippingAdministrator,
    UserRoleCode.JMCarePlanAdministrator
  ],
  [ModuleName.Security]: [
    ...defaultRoles,
  ],
  [ModuleName.Entity]: [
    ...defaultRoles,
  ],
  [ModuleName.Party]: [
    ...defaultRoles,
  ],
  [ModuleName.BulkUpload]: [
    ...defaultRoles,
  ],
  [ModuleName.TermsAndConditions]: [
    ...defaultRoles,
  ],
  [ModuleName.RetailLocation]: [
    ...defaultRoles,
    UserRoleCode.JMCarePlanAdministrator,
    UserRoleCode.JMCarePlanCallCenter,
    UserRoleCode.JMCarePlanSales,
    UserRoleCode.JMCarePlanClaimProcessingManager,
    UserRoleCode.JMCarePlanClaimProcessor,
    UserRoleCode.JMCarePlanFinance
  ],
  [ModuleName.ProgramEnrollments]: [
    ...defaultRoles,
    UserRoleCode.JMPointOfSaleAdministrator,
    UserRoleCode.JMJewelerProgramsFinance
  ],
  [ModuleName.IncentivePrograms]: [
    ...defaultRoles,
    UserRoleCode.JMPointOfSaleAdministrator,
    UserRoleCode.JMJewelerProgramsFinance
  ]
};

export const moduleRoleAccess = {
  communication: moduleRoleAccessMap[ModuleName.Communication],
  risk: moduleRoleAccessMap[ModuleName.Risk],
  identity: moduleRoleAccessMap[ModuleName.Identity],
  membership: moduleRoleAccessMap[ModuleName.Membership],
  content: moduleRoleAccessMap[ModuleName.Content],
  payment: moduleRoleAccessMap[ModuleName.Payment],
  shipping: moduleRoleAccessMap[ModuleName.Shipping],
  billing: moduleRoleAccessMap[ModuleName.Billing],
  appraisals: moduleRoleAccessMap[ModuleName.Appraisals],
  pointOfSaleIntegration: moduleRoleAccessMap[ModuleName.PointOfSaleIntegration],
  diamondMarketplace: moduleRoleAccessMap[ModuleName.DiamondMarketplace],
  memo: moduleRoleAccessMap[ModuleName.Memo],
  studio: moduleRoleAccessMap[ModuleName.Studio],
  jewelerPages: moduleRoleAccessMap[ModuleName.JewelerPages],
  auction: moduleRoleAccessMap[ModuleName.Auction],
  promoCode: moduleRoleAccessMap[ModuleName.PromoCode],
  security: moduleRoleAccessMap[ModuleName.Security],
  entity: moduleRoleAccessMap[ModuleName.Entity],
  party: moduleRoleAccessMap[ModuleName.Party],
  retailLocation: moduleRoleAccessMap[ModuleName.RetailLocation],
  programEnrollments: moduleRoleAccessMap[ModuleName.ProgramEnrollments],
  incentivePrograms: moduleRoleAccessMap[ModuleName.IncentivePrograms],
  bulkUpload:  moduleRoleAccessMap[ModuleName.BulkUpload],
  termsAndConditions:  moduleRoleAccessMap[ModuleName.TermsAndConditions],

  forModule(moduleName: ModuleName): UserRoleCode[] {
    return moduleRoleAccessMap[moduleName];
  },
};
