import { ColumnSettings, MasterTableSettings } from '@zing/grid';
import { MatchingStatus } from "../../enums/matching-status";

export const columnSettings: ColumnSettings[] = [
  {
    name: 'matchingStatus',
    sort: true,
    order: 0,
    label: 'Status',
    filter: {
      simple: true,
      matchMode: 'eq',
      options: [
        { value: MatchingStatus.PossibleMatch.toString(), label: MatchingStatus.PossibleMatch.toString() },
        { value: MatchingStatus.ReviewedBlocked.toString(), label: MatchingStatus.ReviewedBlocked.toString() },
        { value: MatchingStatus.ReviewedNoActionNeeded.toString(), label: MatchingStatus.ReviewedNoActionNeeded.toString() }
      ]
    }
  },
  {
    name: 'highestMatchingScore',
    sort: true,
    order: 0,
    label: 'Score',
    filter: {
      display: 'menu',
      simple: true,
      type: 'numeric',
    }
  },
  {
    name: 'createdOn',
    sort: true,
    order: 0,
    label: 'Scan Initiated Date',
  },
  {
    name: 'reasonToDecision',
    sort: true,
    order: 0,
    label: 'Comments',
    filter: {
      display: 'menu',
      simple: true,
      type: 'text',
    }
  },
  {
    name: 'reviewedOn',
    sort: true,
    order: 0,
    label: 'Last Updated',
  },
];

export const masterSettings: MasterTableSettings = {
  scrollable: false,
  showCurrentPageReport: true,
  displayPagination: true
};
