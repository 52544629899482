import { Component, Inject } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { DefaultConfirmationWithCommentData } from '../../../models/default-confirmation-with-comment-data';
import { BaseConfirmationWithCommentComponent } from '../base-confirmation-with-comment/base-confirmation-with-comment.component';

@Component({
  selector: 'app-deactivate-confirmation-with-comment',
  templateUrl: '../base-confirmation-with-comment/base-confirmation-with-comment.component.html',
  styleUrls: ['../base-confirmation-with-comment/base-confirmation-with-comment.component.scss']
})
export class DeactivateConfirmationWithCommentComponent extends BaseConfirmationWithCommentComponent {
  public override title = 'Deactive';
  public override displayText = 'Are you sure you want to deactivate this user?';
  public override inputPlaceholderMessage = 'Reason to deactivate';
  public override confirmActionButtonText = 'Deactivate';

  constructor(
    public fb: UntypedFormBuilder,
    public dialogRef: MatDialogRef<DeactivateConfirmationWithCommentComponent>,
    @Inject(MAT_DIALOG_DATA) data: DefaultConfirmationWithCommentData
  ) {
    super(fb, dialogRef, data);
  }
}
