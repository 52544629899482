import { ColumnSettings, MasterTableSettings } from '@zing/grid';
import { ReportPeriod } from "../../../shared/models/report-request.model";

export const columnSettings: ColumnSettings[] = [
    {
      name: 'reportType',
      sort: false,
      order: 0,
      label: 'Report Type',
      width: 100,
      filter: {
        simple:true,
        matchMode:'eq',
        options: []
      }
    },
    {
      name: 'createdOn',
      sort: true,
      order: 1,
      label: 'Created At',
      width: 100
    },
    {
      name: 'isScheduled',
      sort: true,
      order: 2,
      label: 'Scheduled For',
      width: 100
    },
    {
      name: 'reportProgress',
      sort: false,
      order: 3,
      label: 'Status',
      width: 100,
      filter: {
        simple:true,
        matchMode:'eq',
        options: []
      }
    },
    {
      name: 'startEnd',
      sort: false,
      order: 4,
      label: 'Start Date - End Date',
      width: 100,
    },
    {
      name: 'period',
      sort: false,
      order: 5,
      label: 'Period',
      width: 100,
      filter: {
        simple:true,
        matchMode:'eq',
        options: [
          {label: 'Custom', value: ReportPeriod.Custom},
          {label: 'Last Month', value: ReportPeriod.LastMonth},
          {label: 'Last Quarter', value: ReportPeriod.LastQuarter}
        ]
      }
    },
    {
      name: 'action',
      sort: false,
      order: 6,
      label: 'Actions',
      width: 60,
    },
  ];
  
  export const masterSettings: MasterTableSettings = {
    scrollable: false,
    showCurrentPageReport: true,
    displayPagination: true
  };
