import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { ApiLogsService } from '../../../services/api-logs.service';
import { ActivatedRoute, Router } from '@angular/router';
import { LayoutService } from '../../../../layouts/layout.service';
import { ApiLog } from '../../../models/api-log';
import { columnSettings, masterSettings } from './api-log-list.config';
import { ODataQuery } from '@zing/grid';

@Component({
  selector: 'app-api-log-list',
  templateUrl: './api-log-list.component.html',
  styleUrls: ['./api-log-list.component.scss']
})
export class ApiLogListComponent implements OnInit, OnDestroy {

  public subscriptions = new Array<Subscription>();
  public isLoading;
  public itemsToDisplay = [];
  public dataSource;
  pageInfo = {
    currentPage: 0,
    hasMorePages: false,
    previousPages: []
  };
  apiName: any;

  public state: ODataQuery;

  columnSettings = columnSettings;
  masterSettings = masterSettings;
  totalCount = 0;
  data: any[] = [];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private layoutService: LayoutService,
    private apiLogService: ApiLogsService,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.apiName = this.route.snapshot.data['apiName'];
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  loadData(state: ODataQuery){
    this.state = state;
    this.getApiLogs(this.apiName, 1);
  }

  goToNextPage(event) {
    event.preventDefault();
    if  (!this.pageInfo.hasMorePages) {
      return;
    }

    const partitionKey = btoa(this.dataSource['partitionKey']);
    const rowKey = btoa(this.dataSource['rowKey']);

    this.getApiLogs(
      this.apiName,
      this.pageInfo.currentPage + 1,
      null,
      partitionKey,
      rowKey
    );
  }

  goToPreviousPage(event) {
    event.preventDefault();
    if (this.pageInfo.currentPage === 1) {
      return;
    }

    const previousPageDetails = this.pageInfo.previousPages[this.pageInfo.previousPages.length - 2];
    const partitionKey = previousPageDetails['partitionKey'];
    const rowKey = previousPageDetails['rowKey'];

    this.getApiLogs(
      this.apiName,
      this.pageInfo.currentPage - 1,
      null,
      partitionKey,
      rowKey
    );
  }

  getApiLogs(
    apiName: string,
    page?: number,
    filter?: string,
    partitionKey?: string,
    rowKey?: string
  ) {
    this.isLoading = true;
    this.cdr.detectChanges();
    const sub = this.apiLogService
      .getApiLogs(apiName, partitionKey, rowKey)
      .subscribe(
        data => {
          this.isLoading = false;
          if (data) {
            if (page > this.pageInfo.currentPage) {
              this.pageInfo.previousPages.push({ partitionKey, rowKey });
            } else {
              this.pageInfo.previousPages.pop();
            }
            this.pageInfo.currentPage = page;
            this.pageInfo.hasMorePages = data['hasMoreItems'];
            this.dataSource = data;
            this.itemsToDisplay = [...data['items']];
            this.cdr.detectChanges();
          }
        },
        () => {
          this.isLoading = false;
          this.cdr.detectChanges();
          this.layoutService.showUIMessage('Unable to get logs for API');
        }
      );

    this.subscriptions.push(sub);
  }

  goToDetails(apiLog: ApiLog) {
    this.apiLogService.apiLogForDetails = apiLog;
    this.router.navigate(['detail'], { relativeTo: this.route });
  }

}
