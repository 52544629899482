import { Injectable } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { BehaviorSubject, of } from 'rxjs';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { UserRoleCode } from '../persons/enums/user-role-code';
import { PersonUserRole } from '../persons/models/person-user-role.model';
import { Person } from '../persons/models/person.model';
import { PersonService } from '../persons/services/person.service';
import { BusinessServiceType } from './enums/business-service-type';

@Injectable({
  providedIn: 'root'
  })
export class AuthService {

  private _isAuthenticated: boolean;
  private _user: Person;
  private _personDetailsLoaded = false;

  public personLoaded = new BehaviorSubject(this._personDetailsLoaded);
  accessToken: any;
  tokenPayload: any;

  constructor(
    private personService: PersonService,
    private oidcService: OidcSecurityService
  ) { }

  /**
   * Return information wether user is logged in or not
   */
  public get isAuthenticated() {
    return this._isAuthenticated;
  }

  /**
   * Returns name of the currently logged in user by concatenating first and last name
   * If there is no user date return 'Unknown'
   */
  public get userName(): string {
    const { firstName, lastName } = this._user || {} as Person;

    return this._user
      ? `${firstName} ${lastName}`
      : 'Unknown';
  }

  /**
   * Determines wether current user has a Super administrator role assigned
   */
  public get isSuperAdministrator(): boolean {
    return this.userPlatformRoles
      .some(role => role.code === UserRoleCode.SuperAdministrator);
  }

  /**
   * Determines wether current user has a Super administrator or Platform administrator role assigned
   */
  public get isSuperOrPlatformAdministrator(): boolean {
    return this.userPlatformRoles
      .some(role => role.code === UserRoleCode.SuperAdministrator || role.code === UserRoleCode.PlatformAdministrator);
  }

  /**
   * Determines wether current user has a Platform administrator role assigned
   */
  public get isPlatformAdmin(): boolean {
    return this.userPlatformRoles
      .some(role => role.code === UserRoleCode.PlatformAdministrator);
  }

  public get isShippingAdmin(): boolean {
    return this.userPlatformRoles
      .some(role => role.code === UserRoleCode.JMShippingAdministrator);
  }

  public get isCommercialLinesAdmin(): boolean {
    return this.userPlatformRoles
      .some(role => role.code === UserRoleCode.JMCommercialLinesAdministrator);
  }

  public get isAppraisalAdmin(): boolean {
    return this.userPlatformRoles
      .some(role => role.code === UserRoleCode.JMAppraisalAdministrator);
  }

  public get isAppraisalSales(): boolean {
    return this.userPlatformRoles
      .some(role => role.code === UserRoleCode.JMAppraisalSales);
  }

  public get isPartnerGatewayAdmin(): boolean {
    return this.userPlatformRoles
      .some(role => role.code === UserRoleCode.JMPartnerGatewayAdministrator);
  }

  public get isPlatformSupportLevelOne(): boolean {
    return this.userPlatformRoles
      .some(role => role.code === UserRoleCode.PlatformSupportL1);
  }

  public get isMarketplaceAdmin(): boolean {
    return this.userPlatformRoles
      .some(role => role.code === UserRoleCode.JMMarketplaceAdministrator);
  }

  public get isStudioAdmin(): boolean {
    return this.userPlatformRoles
      .some(role => role.code === UserRoleCode.JMStudioAdministrator);
  }

  public get isPersonalLinesUser(): boolean {
    return this.userPlatformRoles
      .some(role =>
        role.code === UserRoleCode.JMPersonalLinesClaimsAdministrator ||
        role.code === UserRoleCode.JMPersonalLinesClaimsExaminer ||
        role.code === UserRoleCode.JMPersonalLinesClaimsCallCenter);
  }

  public get isPOSAdmin() {
    return this.userPlatformRoles
      .some(role => role.code === UserRoleCode.JMPointOfSaleAdministrator);
  }

  public get isCustomerService() {
    return this.userPlatformRoles
      .some(role => role.code === UserRoleCode.JMCustomerService);
  }

  public get isJewelerProgramsFinance() {
    return this.userPlatformRoles
      .some(role => role.code === UserRoleCode.JMJewelerProgramsFinance);
  }

  public get isRegulatoryComplianceAdministrator() {
    return this.userPlatformRoles
      .some(role => role.code === UserRoleCode.RegulatoryComplianceAdministrator);
  }

  public get isJMIdentitySupportLevelOne(): boolean {
    return this.userPlatformRoles
      .some(role => role.code === UserRoleCode.JMIdentitySupportLevel1);
  }

  public get isViewOnly(): boolean {
    return this.userPlatformRoles
      .some(role => role.code === UserRoleCode.ViewOnly);
  }

  public get isMembershipAdmin(): boolean {
    return this.userPlatformRoles
      .some(role => role.code === UserRoleCode.MembershipAdministrator);
  }

  public get canChangeCarePlanSubscription(): boolean {
    return this.userPlatformRoles
      .some(role =>
        role.code === UserRoleCode.JMCarePlanAdministrator ||
        role.code === UserRoleCode.JMCarePlanCallCenter ||
        role.code === UserRoleCode.JMCarePlanSales ||
        role.code === UserRoleCode.JMCarePlanFinance);
  }

  public get isMembershipReportViewer(): boolean {
    return this.userPlatformRoles
      .some(role => role.code === UserRoleCode.JMMembershipReportViewer);
  }

  public get isJewelerPagesAdmin(): boolean {
    return this.userPlatformRoles
      .some(role =>
        role.code === UserRoleCode.JewelerPagesAdministrator);
  }

  public get isAuctionAdmin(): boolean {
    return this.userPlatformRoles
      .some(role =>
        role.code === UserRoleCode.JMAuctionAdministrator);
  }

  public get isRegulatoryComplianceAdmin(): boolean {
    return this.userPlatformRoles
      .some(role =>
        role.code === UserRoleCode.RegulatoryComplianceAdministrator);
  }

  /**
   * Returns a list of platform specific user roles assigned to the current user, expect PlatformUser and JMPersonalLinesClaimsSpecialist.
   */
  public get userPlatformRoles(): Array<PersonUserRole> {
    return this._user
      ? this._user.roles
        .filter(role => role.companyId === null && role.locationId === null)
        .map(role => role.userRoles.filter(personRole => !personRole.code.includes(UserRoleCode.PlatformUser)
          && !personRole.code.includes(UserRoleCode.JMPersonalLinesClaimsSpecialist)))
        .reduce((acc: Array<PersonUserRole>, cur: Array<PersonUserRole>) => [...acc, ...cur], [])
      : [];
  }

  /**
   * Returns user reference Id
   */
  public get userReferenceId() {
    if (this._personDetailsLoaded) {
      return this._user.referenceId;
    } else {
      try {
        const userData = JSON.parse(sessionStorage.getItem('userData'));

        if (userData) {
          return userData['sub'];
        }

      } catch (error) {
        // Unable to convert data
      }
    }
  }

  /**
   * Returns user id
   */
  public get userId() {
    return this._personDetailsLoaded ? this._user.id : null;
  }

  /**
   * Try to logout user from application
   */
  public logout() {
    this.oidcService.checkAuth().pipe(take(1)).subscribe(() => {
      this.oidcService.logoffAndRevokeTokens()
        .pipe(take(1))
        .subscribe();
    });
  }

  /**
   * Try to get the details for currently logged in user
   */
  public getUserDetails(forceLoad = false) {
    if (!this._user || forceLoad) {
      return this.personService
        .getProfileInfo()
        .pipe(tap((user: Person) => {
          this._user = user;
          this._personDetailsLoaded = true;
          this.personLoaded.next(true);
        }));
    }

    return of(this._user);
  }

  public isLoggedIn() {
    return this.oidcService.checkAuth().pipe(
      switchMap(authenticated => {
        this._isAuthenticated = authenticated.isAuthenticated ?? false;
        if (authenticated) {
          return this.getUserDetails();
        }
        return of(null);
      }),
      map(result => result ? true : false)
    );
  }

  public hasPermissionForServiceType(serviceType: BusinessServiceType): boolean {
    if (this.isSuperAdministrator || this.isPlatformAdmin || this.isViewOnly || this.isMembershipAdmin) {
      return true;
    }

    switch (+serviceType) {
      case BusinessServiceType.CarePlan:
        return this.canChangeCarePlanSubscription;
      case BusinessServiceType.Shipping:
        return this.isShippingAdmin;
      case BusinessServiceType.CommercialInsurance:
        return this.isCommercialLinesAdmin;
      case BusinessServiceType.PersonalInsurance:
        return this.isPersonalLinesUser;
      case BusinessServiceType.PartnerGateway:
        return this.isPartnerGatewayAdmin;
      case BusinessServiceType.Appraisal:
        return this.isAppraisalAdmin || this.isAppraisalSales;
      case BusinessServiceType.Marketplace:
        return this.isMarketplaceAdmin;
      case BusinessServiceType.PointOfSaleIntegration:
        return this.isPOSAdmin || this.isCustomerService;
      case BusinessServiceType.Studio:
        return this.isStudioAdmin;
      case BusinessServiceType.JewelerPages:
        return this.isJewelerPagesAdmin;
      case BusinessServiceType.Auction:
        return this.isAuctionAdmin;
      default:
        return this.isPlatformAdmin;
    }
  }

  public hasPOSISubscription() {
    return this._user.company.subscriptions
      .some(subscription => subscription.serviceType === BusinessServiceType.PointOfSaleIntegration &&
        subscription.isActive);
  }

  hasAnyRole(userRoles: UserRoleCode[]) {
    return this.userPlatformRoles.some(role => userRoles.includes(role.code));
  }

  setAccessToken() {
    this.oidcService.getAuthenticationResult()
      .pipe(take(1))
      .subscribe(res => {
        this.accessToken = res?.access_token ?? '';
      });
    this.oidcService.getPayloadFromIdToken()
      .pipe(take(1))
      .subscribe(res => this.tokenPayload = res ?? '');
  }
}
