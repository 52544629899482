<p class="portal-top-text">View all {{ apiName }} API logs</p>
<div class="portal-example-page">
  <div class="portal-example-pager__content portal-example-pager__header-content--push">
    <div class="example-container mat-elevation-z8 grid-container">
      <zing-grid 
        [data]="itemsToDisplay" 
        [tableSettings]="columnSettings" 
        [masterSettings]="masterSettings"
        [totalCount]="totalCount" 
        [dataKey]="'id'" 
        [isLoading]="isLoading"
        (oDataQueryChanged)="loadData($event)"> 

        <ng-template #action let-logItem>
          <div class="text-center">
            <mat-icon color="primary" class="cursorButtons" (click)="goToDetails(logItem)"
              matTooltip="View log details" 
              matTooltipPosition="before"
              matTooltipClass="quartz-tooltip">description</mat-icon>
          </div>
        </ng-template>
      </zing-grid>
      <div class="mt-3">
        <nav aria-label="Page navigation">
          <ul class="pagination justify-content-start m-0">
            <li class="page-item" [class.disabled]="pageInfo.currentPage === 1">
              <a class="page-link" href="#" (click)="goToPreviousPage($event)">Previous</a>
            </li>
            <li class="page-item" [class.disabled]="!pageInfo.hasMorePages">
              <a class="page-link" href="#" (click)="goToNextPage($event)" >Next</a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </div>