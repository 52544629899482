import { ColumnSettings, MasterTableSettings } from '@zing/grid';

export const columnSettings: ColumnSettings[] = [
    {
      name: 'level',
      sort: false,
      order: 0,
      label: 'Level',
      width: 50
    },
    {
      name: 'renderedMessage',
      sort: false,
      order: 1,
      label: 'Rendered Message',
      width: 300
    },
    {
      name: 'timestamp',
      sort: false,
      order: 2,
      label: 'Time',
      width: 100
    },
    {
      name: 'action',
      sort: false,
      order: 3,
      label: 'Actions',
      width: 50
    }
  ];
  
  export const masterSettings: MasterTableSettings = {
    scrollable: false,
    showCurrentPageReport: false,
    displayPagination: false
  };