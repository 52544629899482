<p *ngIf="canSeeReportList" class="portal-top-text">Existing Reports</p>
<div class="portal-example-pager__content" *ngIf="canSeeReportList">
  <div class="list-container example-container">

    <zing-grid 
        [data]="data" 
        [tableSettings]="columnSettings" 
        [masterSettings]="masterSettings"
        [totalCount]="totalCount" 
        [dataKey]="'id'" 
        [additionalFilter]="defaultFilter"
        [isLoading]="isLoading"
        (oDataQueryChanged)="loadData($event)">

        <ng-template zingCustomColumn columnName="reportProgress" let-report>
          {{ report.reportProgress | reportProgress}}
        </ng-template>

        <ng-template zingCustomColumn columnName="reportType" let-report>
          {{ getReportType(report.reportType) }} 
        </ng-template>

        <ng-template zingCustomColumn columnName="createdOn" let-report>
          {{ report.createdOn | date : 'medium' }}
        </ng-template>

        <ng-template zingCustomColumn columnName="isScheduled" let-report>
          {{ report.isScheduled ? (report.scheduledFor | date : 'medium') : '-' }}
        </ng-template>

        <ng-template zingCustomColumn columnName="startEnd" let-report>
          {{ (report.startDate | date : 'mediumDate')}} - {{(report.endDate | date : 'mediumDate')}}
        </ng-template>

        <ng-template zingCustomColumn columnName="period" let-report>
          {{ report.period | reportPeriod}}
        </ng-template>

        <ng-template zingCustomColumn columnName="startEnd" let-report>
          {{ report.createdOn | date : 'medium' }}
        </ng-template>

        <ng-template zingCustomColumn columnName="period" let-report>
          {{ report.period | reportPeriod}}
        </ng-template>

        <ng-template #action let-report>
          <div class="text-center">
            <mat-icon *ngIf="report.reportBlobId && !report.failed" class="cursorButtons" matTooltip="Download report"
              matTooltipPosition="before" matTooltipClass="quartz-tooltip" (click)="onDownload(report.id, report.reportName)">
              cloud_download
            </mat-icon>

            <mat-icon *ngIf="showDeleteIcon(report)"
              class="cursorButtons" matTooltip="Delete report" matTooltipPosition="before"
              matTooltipClass="quartz-tooltip" (click)="onDelete(report.id, report.reportType)">delete
            </mat-icon>

            <mat-icon *ngIf="!report.reportBlobId && !report.failed && !report.isScheduled" class="cursorButtons"
              matTooltip="Report request is being processed" matTooltipPosition="before"
              matTooltipClass="quartz-tooltip" (click)="onRefresh(report)">av_timer
            </mat-icon>

            <mat-icon *ngIf="report.isScheduled && report.reportProgress === reportProgress.Scheduled && report.scheduledFor > currentDate" 
              class="cursorButtons"
              matTooltip="Cancel report" matTooltipPosition="before"
              matTooltipClass="quartz-tooltip" (click)="onCancel(report)">cancel
            </mat-icon>
          </div>
        </ng-template>
      </zing-grid>
    <app-in-progress [loading]="inProgress"></app-in-progress>
  </div>
</div>
