export class DefaultConfirmationWithCommentData {
    title: string;
    displayText: string;
    isConfirmed = false;
  
    constructor(title: string, displayText: string, isConfirmed?: boolean) {
      this.title = title;
      this.displayText = displayText;
      this.isConfirmed = isConfirmed;
    }
  };