<div class="confirmation">
    <h2 mat-dialog-title><mat-icon color="warn">warning</mat-icon>{{ title }}</h2>
    <p>{{ displayText }}</p>
    <div>
        <div *ngIf="isConfirmed">
            <form [formGroup]="confirmationForm">
                <mat-form-field>
                  <input 
                    matInput 
                    type="text" 
                    [placeholder]= "inputPlaceholderMessage" 
                    formControlName="comment">
                  <mat-error 
                    *ngIf="hasErrorOfType('comment', 'required') || hasErrorOfType('comment', 'maxlength')
                          || hasErrorOfType('comment', 'minlength')">
                    Enter text between 25 and 1000 characters
                  </mat-error>
                </mat-form-field>
              </form>
        </div>
        <div class="btn-group" role="group">
            <button *ngIf="isConfirmed" mat-button [disabled]="commentCtrl.invalid" [mat-dialog-close]="confirmationForm.invalid ? '' : commentCtrl.value"> {{confirmActionButtonText}} </button>
            <button mat-button mat-dialog-close>{{ isConfirmed ? 'Cancel' : 'No' }}</button>
            <button *ngIf="!isConfirmed" mat-button (click)="onConfirm()">Yes</button>
        </div>
    </div>
</div>